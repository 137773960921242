import _ from 'lodash';
import React, { Component } from 'react';
import { Button, Tooltip, message } from 'antd';
import { connect } from 'react-redux';
import i18next from 'i18next';
import ExamplesDrawer from './ExamplesDrawer';
import SettingsDrawer from './SettingsDrawer';
import * as Locales from '../locales';

import Exampler from './Exampler';
import Resulter from './Resulter';
import Statister from './Statister';

import LoadingOverlay from './LoadingOverlay';

import { BarChartOutlined } from '@ant-design/icons';
import { setCommonPresets, setAppInitialized, setStep } from '../redux/actions';
import { RESULTER_STATE, STATISTER_STATE, DEFAULT_LANG } from '../const';


class Solver extends Component {
    componentDidMount() {
        this._initSourceList();
        this._initI18n();
    }

    componentDidUpdate(oldProps) {
        const { lang } = this.props;

        if (lang !== oldProps.lang) {
            i18next.changeLanguage(lang);
        }
    }

    _initI18n() {
        const { lang } = this.props;

        const currentLang = lang ? lang : DEFAULT_LANG;


        if (_.size(Locales) > 0) {
            const resources = {};

            _.forEach(Locales, (lang, code) => {
                if (code && typeof code === 'string' && _.isObject(lang)) {
                    resources[code] = lang;
                }
            })

            console.log("Lang resources", currentLang, resources);

            i18next.init({
                lng: currentLang,
                resources,
            }, () => {
                this.props.setAppInitialized(true);
            });
        }
    }

    _initSourceList() {
        const { sourceData } = this.props;

        try {
            const res = [];

            if (sourceData) {
                if (_.isArray(sourceData)) {
                    _.forEach(sourceData, (source, index) => {
                        if (!_.isPlainObject(source) || !source) {
                            throw new Error(i18next.t("wrong_source_item_type"));
                        } else {
                            const { Name, Description, Examples } = source;
                            const o = {};

                            if (!Name || typeof Name !== "string") {
                                throw new Error(i18next.t("wrong_name_prop_provided", { index: index + 1 }));
                            } else {
                                o.Name = Name;
                            }

                            if (!Examples || !_.isArray(Examples) || Examples.length === 0) {
                                throw new Error(i18next.t("no_examples_provided", { index: index + 1 }));
                            } else {
                                o.Examples = Examples;
                            }

                            if (Description && typeof Description === "string") {
                                o.Description = Description;
                            } else {
                                o.Description = "";
                            }

                            res.push(o);
                        }
                    });
                } else {
                    throw new Error(i18next.t("wrong_source_data_given"));
                }

                this.props.setCommonPresets(res);
            }


        } catch (e) {
            message.error(i18next.t("source_init_problems", { error: e.toString() }));
        }
    }

    _getExample(index) {
        const { examples, current } = this.props;

        if (_.isArray(examples) && examples.length > 0 && _.isNumber(current) && current >= 0 && current < examples.length) {
            return examples[current];
        }

        return [];
    }

    statisticPress() {
        this.props.setStep(STATISTER_STATE);
    }

    renderLogo() {
        return (
            <div className="ucmas-logo-container">
                <img src={require('../assets/images/logo.png')} alt="logo" />
            </div>
        );
    }

    renderStep() {
        const { step } = this.props;

        switch (step) {
            case RESULTER_STATE: return <Resulter />;
            case STATISTER_STATE: return <Statister />;
            default: return <Exampler />;
        }
    }

    renderLoading() {
        const { initialized } = this.props;

        if (!initialized) {
            return <LoadingOverlay />;
        }
    }
    
    renderButtons() {
        return (
            <>
                <Tooltip title={i18next.t("examples_drawer_tooltip")} className="float-button bottom-right">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<BarChartOutlined />}
                        onClick={this.statisticPress.bind(this)}
                        size="large"
                    />
                </Tooltip>
            </>
        )
    }

    render() {
        return (
            <>
                {this.renderLoading()}
                <div className="ucmas-solver-container">
                    <ExamplesDrawer />

                    {this.renderStep()}
                    {this.renderLogo()}
                    
                    {this.renderButtons()}

                    <SettingsDrawer />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { initialized } = state.app;
    const { numberCount, isManual, lang } = state.options;
    const { step, current, examples, testPause, testStart, testComplete } = state.solver;

    return {
        initialized,
        lang,
        step,
        current,
        examples,
        numberCount,
        testPause,
        testStart,
        testComplete,
        isManual
    }
};

const mapDispatchToProps = {
    setCommonPresets,
    setAppInitialized,
    setStep
};

export default connect(mapStateToProps, mapDispatchToProps)(Solver);