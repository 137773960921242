import _ from 'lodash';
import {
    TYPE_SET_COMMON_PRESETS
} from '../actions';

const INITIAL_STATE = {
    common: [],
    personal: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPE_SET_COMMON_PRESETS: 
            if (_.isArray(action.payload) && action.payload.length > 0) {
                return { 
                    ...state,
                    common: action.payload 
                }
            }

            return state;

        case TYPE_SET_COMMON_PRESETS: 
            if (_.isArray(action.payload) && action.payload.length > 0) {
                return { 
                    ...state,
                    common: action.payload 
                }
            }

            return state;
            
        default: return state;
    }
}