import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Empty, Button } from 'antd';
import { motion } from 'framer-motion';
import { setStep } from '../redux/actions';
import { EXAMPLER_STATE } from '../const';
import moment from 'moment';
import i18next from 'i18next';

import {
    SmileTwoTone,
    FrownTwoTone,
    ArrowLeftOutlined
} from '@ant-design/icons';

const Columns = [
    {
        title: () => i18next.t("stat.start_in"), //'Start in',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (text, record) => (
            <div>
                <div>{moment(record.startTime).format('DD.MM.YYYY')}</div>
                <div>{moment(record.startTime).format('HH:mm:ss')}</div>
            </div>
        )
    },
    {
        title: () => i18next.t("stat.duration"),  //'Duration',
        dataIndex: 'endTime',
        key: 'endTime',
        render: (text, record) => (<span>{moment(record.endTime - record.startTime).format("mm:ss")}</span>)
    },
    {
        title: () => i18next.t("stat.total"), //'Examples',
        dataIndex: 'total',
        key: 'total',
        render: (text, record) => (record.total && record.total >= 0 ? text : "-")
    },
    {
        title: () => i18next.t("stat.correct"),  //'Correct',
        dataIndex: 'correct',
        key: 'correct',
        render: (text, record) => (record.correct && record.correct >= 0 ? text : "-")
    },
    {
        title: () => i18next.t("stat.is_done"),  //'Is done?',
        dataIndex: 'isComplited',
        key: 'isComplited',
        render: (text, record) => (
            record.isComplited === true ?
                <SmileTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px' }} /> :
                <FrownTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px' }} />)
    }
];

class Statister extends Component {
    back() {
        this.props.setStep(EXAMPLER_STATE);
    }

    renderEmpty() {
        return (
            <Empty
                description={"No statistics available"}
            >
                <Button
                    type="primary"
                    icon={<ArrowLeftOutlined />}
                    onClick={this.back.bind(this)}
                >
                    {i18next.t("buttons.return")}
                </Button>
            </Empty>
        );
    }

    renderTable() {
        const { lessons } = this.props;
        const data = _.map(lessons, (o) => o);

        console.log("Statistics", data);

        return (
            <div className="ucmas-lessons-statistic">
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                >
                    <Table 
                        columns={Columns} 
                        dataSource={data}
                        scroll={{ y: 300 }}
                    />
                </motion.div>

                <Button
                    icon={<ArrowLeftOutlined />}
                    type="primary"
                    onClick={this.back.bind(this)}
                >
                    {i18next.t("buttons.return")}
                </Button>
            </div>
        );
    }

    render() {
        const { lessons } = this.props;

        if (!lessons || _.size(lessons) === 0) {
            return this.renderEmpty();
        }

        return this.renderTable();

    }
}

const mapStateToProps = (state) => {
    const { lang } = state.options;
    const { lessons } = state.lessons;

    console.log("lessons: ", lessons);

    return {
        lang,
        lessons
    }
};

const mapDispatchToProps = {
    setStep
};

export default connect(mapStateToProps, mapDispatchToProps)(Statister);