import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Tooltip, InputNumber, Switch, Select, Form } from 'antd';
import i18next from 'i18next';
import { SettingOutlined } from '@ant-design/icons';

import { setPropValue } from '../redux/actions';

class SettingsDrawer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    show() {
        this.setState({ visible: true });
    };

    hide() {
        this.setState({ visible: false });
    };

    renderModeToggler() {
        const { isManual } = this.props;

        return (
            <Form.Item label={i18next.t("settings.manual_mode_label")}>
                <Switch
                    checked={isManual}
                    onChange={(val) => this.props.setPropValue("isManual", val)}
                />
            </Form.Item>
        );
    }

    renderTimerInput() {
        const { timer } = this.props;

        return (
            <Form.Item label={i18next.t("settings.timer_value_label")}>
                <InputNumber
                    min={1}
                    value={timer}
                    onChange={(val) => this.props.setPropValue("timer", Number(val))}
                />
            </Form.Item>

        );
    }

    renderDelayInput() {
        const { delay, isManual } = this.props;

        return (
            <Form.Item label={i18next.t("settings.delay_value_label")}>
                <InputNumber
                    disabled={isManual}
                    min={1}
                    value={delay}
                    onChange={(val) => this.props.setPropValue("delay", Number(val))}
                />
            </Form.Item>

        );
    }

    renderTimerToggler() {
        const { showExampleTimer } = this.props;

        return (
            <Form.Item label={i18next.t("settings.show_example_timer_label")}>
                <Switch
                    checked={showExampleTimer}
                    onChange={(val) => this.props.setPropValue("showExampleTimer", val)}
                />
            </Form.Item>
        )
    }

    renderDelayToggler() {
        const { showDelayTimer, isManual } = this.props;

        return (
            <Form.Item label={i18next.t("settings.show_delay_timer_label")}>
                <Switch
                    disabled={isManual}
                    checked={showDelayTimer}
                    onChange={(val) => this.props.setPropValue("showDelayTimer", val)}
                />
            </Form.Item>
        )
    }

    renderSoundToggler() {
        const { playSound, isManual } = this.props;

        return (
            <Form.Item label={i18next.t("settings.sound_toggler_label")}>
                <Switch
                    disabled={isManual}
                    checked={playSound}
                    onChange={(val) => this.props.setPropValue("playSound", val)}
                />
            </Form.Item>
        )
    }

    renderBipDelayInput() {
        const { bipDelay, isManual } = this.props;

        return (
            <Form.Item label={i18next.t("settings.sound_delay_label")}>
                <InputNumber
                    disabled={isManual}
                    value={bipDelay}
                    onChange={(val) => this.props.setPropValue("bipDelay", Number(val))}
                />
            </Form.Item>

        );
    }

    renderLangSelector() {
        const { lang, langs } = this.props;

        if (langs && _.isPlainObject(langs) && _.size(langs) > 0) {
            return (
                <Form.Item label={i18next.t("settings.language_selector_label")} >
                    <Select
                        value={lang}
                        onChange={(val) => this.props.setPropValue("lang", val)}
                    >
                        {_.map(langs, (l, k) => <Select.Option value={k}>{l}</Select.Option>)}
                    </Select>
                </Form.Item>
            );
        }

        return null;
    }


    render() {
        const { visible } = this.state;

        return (
            <div className="ucmas-drawer-container">
                <Tooltip title={i18next.t("settings_drawer_tooltip")} className="float-button top-right">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<SettingOutlined />}
                        onClick={this.show}
                        size="large"
                    />
                </Tooltip>

                <Drawer
                    width={300}
                    title={i18next.t("settings_drawer_title")}
                    placement="right"
                    closable={false}
                    onClose={this.hide}
                    visible={visible}
                >
                    <div className="ucmas-drawer-container">
                        <Form
                            labelCol={{ span: 14 }}
                            wrapperCol={{ span: 20 }}
                            layout="horizontal"
                        >
                            {this.renderModeToggler()}
                            {this.renderTimerInput()}
                            {this.renderDelayInput()}
                            {this.renderTimerToggler()}
                            {this.renderDelayToggler()}
                            {this.renderBipDelayInput()}
                            {this.renderSoundToggler()}
                            {this.renderLangSelector()}
                        </Form>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isManual,
        timer,
        delay,
        doBip,
        manualMode,
        showExampleTimer,
        showDelayTimer,
        playSound,
        bipDelay,
        lang,
        langs
    } = state.options;

    return {
        isManual,
        timer,
        delay,
        doBip,
        manualMode,
        showExampleTimer,
        showDelayTimer,
        playSound,
        bipDelay,
        lang,
        langs
    };
};

const mapDispatchToProps = {
    setPropValue
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDrawer);

