export default {
    translation: {
      "yes": "Yes",
      "no": "no",
      "test_complete_sucessfully": "You'v successfuly complete the test!",
      "test_complete_sucessfully_subtitle": "You may check your tests or pass another test",
      "begin_test_title": "Please press \"Start Test\" button to begin testing",
      "example_header": "Example #{{number}}",
      "source_added_message": "Source {{name}} was added;",
      "drawer_no_examples": "No examples",
      "remove_examples_warning": "Are you sure remove all examples?",
      "add_new_example_title": "Add new example:",
      "add_new_example_palceholder": "Enter example",
      "examples_tab_name": "Examples",
      "examples_drawer_tooltip": "Examples list",
      "sets_tab_name": "Presets",
      "resulter_no_examples": "No examples",
      "settings_drawer_tooltip": "Settings",
      "settings_drawer_title": "Settings",
      "delete_all_examples": "Delete all",
      "check_results_text": "Please carefuly check all results and mark all correct answer you made",
      
      settings: {
        "manual_mode_label":"Manual mode",
        "timer_value_label":"Timer value (sec)",
        "delay_value_label":"Delay value (sec)",
        "show_example_timer_label":"Show example timer",
        "show_delay_timer_label":"Show delay timer",
        "sound_toggler_label":"Play bip sound",
        "sound_delay_label":"Bip delay (sec)",
        "language_selector_label":"Language",
      },
      
      buttons: {
        "pause": "Pause",
        "stop": "Stop",
        "resume": "Resume",
        "next": "Next",
        "check_result": "Check results",
        "new_test": "New test",
        "start_test": "Start test",
        "end_test": "End test",
        "return": "Return"
      },

      errors: { 
        "wrong_example_format": "Wrong example format",
        "wrong_example_len": "Wrong example length; Should be in range from 2 to 5 digits.",
        "appling_source_error": "Error while appling source: {{error}}",
        "wrong_source_item_type": "source item should be an object",
        "wrong_name_prop_provided": "wrong \"Name\" prop provided to {{index}} source item; expected non-empty string",
        "no_examples_provided": "no examples provided in source {{index}}; expected array of strings;",
        "wrong_source_data_given": "source data should be an array of objects",
        "source_init_problems": "Source initiation problem: {{error}}",
        "cant_change_ecamples_list_warning": "You can't change examples list while testing"
      },

      stat: {
        "start_in": "Start in",
        "duration": "Duration",
        "total": "Examples",
        "correct": "Correct",
        "is_done": "Is done?",
      }
    }
  };