export default {
  translation: {
    "yes": "Да",
    "no": "Нет",
    "test_complete_sucessfully": "Вы успешно прошли тест!",
    "test_complete_sucessfully_subtitle": "Вы можете проверить ваши результаты или пройти другой тест",
    "begin_test_title": "Пожалуйста нжмите кнопку \"Начать тест\" чтобы начать тестирование",
    "example_header": "Пример #{{number}}",
    "source_added_message": "Примеры из набора {{name}} были добавлены;",
    "drawer_no_examples": "Нет примеров",
    "remove_examples_warning": "Вы уверены, что хотите удалить все примеры?",
    "add_new_example_title": "Добавить новый пример:",
    "add_new_example_palceholder": "Введите пример",
    "examples_tab_name": "Примеры",
    "examples_drawer_tooltip": "Список примеров",
    "sets_tab_name": "Наборы",
    "resulter_no_examples": "Нет примеров",
    "settings_drawer_tooltip": "Настройки",
    "settings_drawer_title": "Настройки",
    "delete_all_examples": "Удалить всё",
    "check_results_text": "Пожалуйста, внимательно проверьте все ответы и отметьте верные",

    settings: {
      "manual_mode_label": "Ручной режим",
      "timer_value_label": "Таймер (сек)",
      "delay_value_label": "Перерыв (сек)",
      "show_example_timer_label": "Таймер пример",
      "show_delay_timer_label": "Таймер перерыва",
      "sound_toggler_label": "Проигрывать звук",
      "sound_delay_label": "Задержка звука",
      "language_selector_label": "Язык",
    },
    buttons: {
      "pause": "Пауза",
      "stop": "Стоп",
      "resume": "Возобновить",
      "next": "Следующий",
      "check_result": "Проверить результат",
      "new_test": "Новый тест",
      "start_test": "Начать тест",
      "end_test": "Закончить тест",
      "return": "Вернуться"
    },

    errors: {
      "wrong_example_format": "Неправильный формат примера",
      "wrong_example_len": "Неправильная длина примера; пример должен содержать от двух до пяти цифр.",
      "appling_source_error": "Ошибка добавления набора: {{error}}",
      "wrong_source_item_type": "набор должен быть объектом JavaScript",
      "wrong_name_prop_provided": "неправильное свойство \"Name\" задано для набора {{index}}; ожидается строка.",
      "no_examples_provided": "для набора {{index}} не заданы примеры; ожидается массив строк;",
      "wrong_source_data_given": "набор должен быть массивом объектов.",
      "source_init_problems": "Ошибка инициализации наборов: {{error}}",
      "cant_change_ecamples_list_warning": "Вы не мождете менять список примеров во время тестирования"
    },

    stat: {
      "start_in": "Дата",
      "duration": "Длительность",
      "total": "Всего примеров",
      "correct": "Правильных ответов",
      "is_done": "Закончен?",
    }
  }
};