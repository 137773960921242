import _ from 'lodash';
import { SET_OPTIONS_PROP } from '../actions';

const INITIAL_STATE = {
    timer: 10,
    delay: 2,
    bipDelay: 1,
    doBip: false,
    manualMode: false,
    showExampleTimer: true,
    showDelayTimer: true,
    playSound: true,
    lang: "en",
    
    langs: {
        "ru": "Русский",
        "en": "English",
    }
};

export default (state = INITIAL_STATE, action ) => {
    if (!action || !_.isPlainObject(action) || !action.type || typeof action.type !== 'string') {
        return state;
    }

    switch (action.type) {
        case SET_OPTIONS_PROP: 
            if (_.isObject(action.payload)) {
                if (action.payload.prop && typeof action.payload.prop === "string") {
                    console.log("setPropValue reducer: ", action);

                    return {
                        ...state,
                        [action.payload.prop]: action.payload.value || null
                    };
                }
            }
            return state;
        default: return state;
    }
};