import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Solver from './components/Solver'; 
import configureStore from './configureStore';

import 'antd/dist/antd.css';
import './assets/styles/main.scss';

import data from './data/examples_2.json';

function App() {
  const cfg = configureStore({}, {});

  return (
    <div className="ucmas-app-container">
      <Provider store={cfg.store} >
        <PersistGate
            loading={null}
            persistor={cfg.persistor}
        >
          <Solver sourceData={data} />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
