import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import ReduxThunk from 'redux-thunk';

import rootReducer from './redux/reducers';
 
const persistConfigDefauult = {
    key: 'ucmas-solver',
    storage,
    blacklist: ["app"]  // remove from blacklist
};
 
export default (persistConfig, initState = {}) => {
    let config = persistConfigDefauult;

    if (persistConfig && typeof persistConfig === 'object') {
        config = { ...config, persistConfig };
    }

    const persistedReducer = persistReducer(config, rootReducer);

    const store = createStore(persistedReducer, initState, applyMiddleware(ReduxThunk));
    const persistor = persistStore(store);
  
    return { store, persistor };
};
