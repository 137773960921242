export const TYPE_SET_COMMON_PRESETS = "TYPE_SET_COMMON_PRESETS";
export const TYPE_SET_PESONAL_PRESETS = "TYPE_SET_PESONAL_PRESETS";
export const TYPE_ADD_PESONAL_PRESET = "TYPE_ADD_PESONAL_PRESET";
export const TYPE_REMOVE_PESONAL_PRESET = "TYPE_REMOVE_PESONAL_PRESET";

export const setCommonPresets = (presets) => {
    console.log("Settings common presets: ", presets);
    
    return {
        type: TYPE_SET_COMMON_PRESETS,
        payload: presets
    }
} 

export const setPersonalPresets = (presets) => {
    console.log("Settings personal presets: ", presets);

    return {
        type: TYPE_SET_PESONAL_PRESETS,
        payload: presets
    }
} 

export const addPersonalPreset = (name, preset) => {
    console.log("adding personal preset: ", name, preset);

    return {
        type: TYPE_ADD_PESONAL_PRESET,
        payload: {

        }
    }
} 

export const removePersonalPreset = (id) => {
    console.log("removing personal preset: ", id);

    return {
        type: TYPE_REMOVE_PESONAL_PRESET,
        payload: id
    }
} 
