import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Empty } from 'antd';
import i18next from 'i18next';
import classnames from 'classnames';

import { endTest } from '../redux/actions';

import { motion } from "framer-motion";

class Resulter extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rightResults: []
        }

        this.handleComplete = this.handleComplete.bind(this);
    }

    handleComplete() {
        const { rightResults } = this.state;
        const { sid, examples } = this.props;

        this.props.endTest(sid, examples.length, rightResults.length);
    }

    handleSelect(index) {
        const { rightResults } = this.state;

        
        let res = [...rightResults];

        if (res.indexOf(index) !== -1) {
            res = _.without(res, index);
        } else {
            res.push(index);
        }

        this.setState({ rightResults: res });
    }

    renderResults() {
        const { rightResults } = this.state;
        const { examples } = this.props;

        if (!examples || _.size(examples) === 0) {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={i18next.t("resulter_no_examples")} />;
        }

        const columns = examples.length >= 10 ? 10 : examples.length;

        return (
            <div className={`result-grid type-${columns}`}>
                {_.map(examples, (item, index) => {

                    return (
                        <motion.div
                            key={"result_cell_" + index}
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{ type: "spring", stiffness: 200, duration: 0.5, delay: 0.05 * index }}
                        >
                            <div
                                className={classnames("cell", { "selected": rightResults.indexOf(index) >= 0 })}
                                onClick={this.handleSelect.bind(this, index)}
                            >
                                <div class="number">№{index + 1}</div>
                                <div className="value">{_.reduce(item, (sum, d) => sum + d)}</div>
                            </div>
                        </motion.div>
                    );
                })
                }
            </div >
        );
    }

    renderControls() {
        const buttons = [];

        buttons.push(<Button type="primary" className="button" size="large" onClick={this.handleComplete}>{i18next.t("buttons.end_test")}</Button>);

        return (
            <div className="contrlos">
                {buttons}
            </div>
        );
    }

    render() {
        return (<>
            {this.renderResults()}
            {this.renderControls()}
        </>);
    }
}

const mapStateToProps = (state) => {
    const { lang } = state.options;
    const { examples, current, currentLesson: sid } = state.solver;
    const { initialized } = state.app;

    return {
        sid,
        initialized,
        lang,
        current,
        examples,
    };
}

const mapDispatch = {
    endTest
};

export default connect(mapStateToProps, mapDispatch)(Resulter);
