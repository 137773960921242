import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Tooltip, message, List, Input, Tabs, Empty, Popconfirm } from 'antd';
import { motion } from "framer-motion";
import { OrderedListOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { selectExample, addExample, removeExample, setExamples, removeAllExamples } from "../redux/actions";

import { checkExample } from '../helpers';

const { TabPane } = Tabs;

class ExamplesDrawer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            inputValue: ''
        };

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.add = this.add.bind(this);
        this.removeAll = this.removeAll.bind(this);
    }

    show() {
        this.setState({ visible: true });
    };

    hide() {
        this.setState({ visible: false });
    };

    add(value) {
        const { testStart } = this.props;

        if (testStart) {
            message.warning(i18next.t("errors.cant_change_ecamples_list_warning"));
            return;
        }

        try {
            if (value) {
                const res = checkExample(value)

                if (res.length > 0) {
                    if (res.length < 2 || res.length > 5) {
                        throw new Error(i18next.t("errors.wrong_example_len"));
                    }

                    this.setState({ inputValue: "" });
                    this.props.addExample(res);
                }
            }
        } catch (e) {
            message.error(e.toString());
        }
    }

    select(index) {
        const { current } = this.props;

        if (current !== index) {
            this.props.selectExample(Number(index));
            this.hide();
        }
    }

    remove(index) {
        const { testStart } = this.props;

        if (testStart) {
            message.warning(i18next.t("errors.cant_change_ecamples_list_warning"));
            return;
        }

        this.props.removeExample(Number(index));
    }

    removeAll() {
        const { testStart } = this.props;

        if (testStart) {
            message.warning(i18next.t("errors.cant_change_ecamples_list_warning"));
            return;
        }

        this.props.removeAllExamples()
    }

    selectSource(index, isPersonal) {
        const { common, personal } = this.props;
        const { testStart } = this.props;

        let sources = isPersonal === true ? personal : common;

        if (testStart) {
            message.warning(i18next.t("errors.cant_change_ecamples_list_warning"));
            return;
        }
        
        try {
            console.log(sources, index);
            if (index >= 0 && sources && _.isArray(sources)) {
                const s = sources[index];

                if (s && _.isPlainObject(s) && _.isArray(s.Examples)) {
                    const res = [];

                    _.forEach(s.Examples, (str) => {
                        const p = checkExample(str);

                        if (p && p.length > 0) {
                            res.push(p);
                        }
                    });

                    if (res.length > 0) {
                        this.props.setExamples(res);
                        message.success(i18next.t("source_added_message", { name: s.Name }));
                    }
                }
            }
        } catch (e) {
            message.error(i18next.t("errors.appling_source_error", { error: e.toString() }));
        }
    }

    renderListItem(example, index) {
        const { current } = this.props;

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <List.Item
                    key={`list-item-${index}`}
                >
                    <div className="list-row" >
                        <div className="position">{index + 1}</div>
                        <div
                            className={`example ${current === index ? "active" : ""}`}
                            onClick={this.select.bind(this, index)}
                        >
                            {example.map(e => <span className="number">{e}</span>)}
                        </div>
                        <div className="actions">
                            <Button
                                type="link"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                size="small"
                                onClick={this.remove.bind(this, index)}
                            />
                        </div>
                    </div>
                </List.Item>
            </motion.div>
        );
    }

    renderExamplesList() {
        const { examples } = this.props;

        if (!examples || examples.length === 0) {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={i18next.t("drawer_no_examples")} />;
        }

        return (
            <div className="ucmas-examples-list">
                <List
                    size="small"
                    dataSource={examples}
                    renderItem={(item, index) => {
                        if (!_.isArray(item)) return null;

                        return this.renderListItem(item, index);

                    }}
                />

                <div className="clear-action-container">
                    <Popconfirm
                        title={i18next.t("remove_examples_warning")}
                        onConfirm={this.removeAll}
                        okText={i18next.t("yes")}
                        cancelText={i18next.t("no")}
                    >
                        <a href="#">{i18next.t("delete_all_examples")}</a>
                    </Popconfirm>
                </div>
            </div>
        );
    }

    renderAddBlock() {
        const { inputValue } = this.state;

        return (
            <div className="add-block">
                <div className="add-input">
                    <div className="label">{i18next.t("add_new_example_title")}</div>
                    <div className="input">
                        <Input.Search
                            allowClear
                            value={inputValue}
                            placeholder={i18next.t("add_new_example_palceholder")}
                            onChange={(e) => this.setState({ inputValue: e.target.value })}
                            onSearch={this.add}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderSourceItem(item, index, type) {
        if (!_.isPlainObject(item)) return null;

        return (
            <List.Item
                key={`source-item-${index}`}
            >
                <div className="row" >
                    <div className="info">
                        <div className="name">{item.Name}</div>
                        {item.Description ? (
                            <div className="description">{item.Description}</div>
                        ) : null}
                    </div>
                    <div
                        className="action"
                        onClick={this.selectSource.bind(this, index)}
                    >
                        <PlusOutlined />
                    </div>
                </div>
            </List.Item>
        );
    }

    renderCommonPresets() {
        const { common } = this.props;

        if (!common || !_.isArray(common) || _.size(common) === 0) return null;

        return (
            <div className="source-list">
                <List
                    size="small"
                    dataSource={common}
                    renderItem={(s, index) => {
                        return this.renderSourceItem(s, index, "common");
                    }}
                />
            </div>
        );
    }

    renderPersonalPresets() {
        const { personal } = this.props;

        if (!personal || !_.isArray(personal) || _.size(personal) === 0) return null;

        return (
            <div className="source-list">
                <List
                    size="small"
                    dataSource={personal}
                    renderItem={(s, index) => {
                        return this.renderSourceItem(s, index, "peronal");
                    }}
                />
            </div>
        );
    }

    renderPresets() {
        const { common, personal } = this.props;

        if ((_.size(common) > 0) || (_.size(personal) > 0)) {
            return (
                <TabPane tab={i18next.t("sets_tab_name")} key="2">
                    <div className="ucmas-drawer-container">
                        {this.renderCommonPresets()}
                        {this.renderPersonalPresets()}
                    </div>
                </TabPane>
            );
        }

        return null;
    }

    render() {
        const { visible } = this.state;

        return (
            <>
                <Tooltip title={i18next.t("examples_drawer_tooltip")} className="float-button top-left">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<OrderedListOutlined />}
                        onClick={this.show}
                        size="large"
                    />
                </Tooltip>

                <Drawer
                    className="no-padding"
                    width={300}
                    placement="left"
                    closable={false}
                    onClose={this.hide}
                    visible={visible}
                >
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab={i18next.t("examples_tab_name")} key="1">
                            <div className="ucmas-drawer-container">
                                {this.renderAddBlock()}
                                {this.renderExamplesList()}
                            </div>
                        </TabPane>

                        {this.renderPresets()}
                    </Tabs>
                </Drawer>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { lang } = state.options;
    const { current, examples, testStart } = state.solver;
    const { common, personal } = state.presets;

    return {
        lang,
        current,
        examples,
        common,
        personal,
        testStart
    }
};

const mapDispatchToProps = {
    selectExample,
    addExample,
    removeExample,
    setExamples,
    removeAllExamples
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamplesDrawer);

