import { TYPE_SET_APP_INITIALIZED } from '../actions';

const INITIAL_STATE = {
    userId: null,
    token: null,
    ttl: null,
    authorized: false,
    initialized: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPE_SET_APP_INITIALIZED: 
            return {
                ...state,
                initialized: !!action.payload
            };
            
        default: return state;
    }
}