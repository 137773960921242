
import moment from 'moment';

import { getLessonSID } from '../../helpers/LessonHelpers';

export const TYPE_SELECT_LESSON = "TYPE_SELECT_LESSON";
export const TYPE_ADD_EXAMPLE = "TYPE_ADD_EXAMPLE";
export const TYPE_REMOVE_EXAMPLE = "TYPE_REMOVE_EXAMPLE";
export const TYPE_REMOVE_ALL_EXAMPLE = "TYPE_REMOVE_ALL_EXAMPLE";
export const TYPE_NEXT_EXAMPLE = "TYPE_NEXT_EXAMPLE";
export const TYPE_START_TEST = "TYPE_START_TEST";
export const TYPE_PAUSE_TEST= "TYPE_PAUSE_TEST";
export const TYPE_RESUME_TEST= "TYPE_RESUME_TEST";
export const TYPE_STOP_TEST = "TYPE_STOP_TEST";
export const TYPE_SELECT_EXAMPLE = "TYPE_SELECT_EXAMPLE";
export const TYPE_SET_EXAMPLES = "TYPE_SET_EXAMPLES";
export const TYPE_SELECT_STEP = "TYPE_SELECT_STEP";
export const TYPE_CHECK_TEST = "TYPE_CHECK_TEST";
export const TYPE_COMPLETE_TEST = "TYPE_COMPLETE_TEST";
export const TYPE_END_TEST = "TYPE_END_TEST";

export const selectLesson = (code) => {
    return {
        type: TYPE_SELECT_LESSON,
        payload: code
    };
}

export const addExample = (numbers) => {
    return {
        type: TYPE_ADD_EXAMPLE,
        payload: numbers
    };
} 

export const removeExample = (index) => {
    return {
        type: TYPE_REMOVE_EXAMPLE,
        payload: index
    };
}

export const removeAllExamples = () => {
    return {
        type: TYPE_REMOVE_ALL_EXAMPLE
    };
}

export const selectExample = (index) => {
    return {
        type: TYPE_SELECT_EXAMPLE,
        payload: index
    };
};

export const nextExample = () => {
    return {
        type: TYPE_NEXT_EXAMPLE
    };
}

export const startTest = (total) => {
    const sid = getLessonSID();
    const startTime = moment().valueOf();

    return {
        type: TYPE_START_TEST,
        payload: { sid, startTime, total }
    };
}

export const pauseTest = () => {
    return {
        type: TYPE_PAUSE_TEST
    };
}

export const resumeTest = () => {
    return {
        type: TYPE_RESUME_TEST
    };
}

export const stopTest = (sid) => {
    const endTime = moment().valueOf();

    return {
        type: TYPE_STOP_TEST,
        payload: { sid, endTime }
    };
}

export const checkTest = () => {
    return {
        type: TYPE_CHECK_TEST
    };
}

export const completeTest = (sid) => {
    const endTime = moment().valueOf();

    return {
        type: TYPE_COMPLETE_TEST,
        payload: { sid, endTime }
    };
}

export const endTest = (sid, total, correct) => {
    return {
        type: TYPE_END_TEST,
        payload: { sid, total, correct }
    };
}

export const setExamples = (examples) => {
    return {
        type: TYPE_SET_EXAMPLES,
        payload: examples
    }
}

export const setStep = (step) => {
    return {
        type: TYPE_SELECT_STEP,
        payload: step
    };
}