import {
    TYPE_START_TEST,
    TYPE_STOP_TEST,
    TYPE_COMPLETE_TEST,
    TYPE_END_TEST
} from '../actions';

const INITIAL_STATE = {
    lessons: {}
};

const DEFAULT_LESSON = {
    sid: null,
    startTime: null,
    endTime: null,
    total: null,
    correct: null,
    isComplited: null,
    isStoped: null,
}

export default (state = INITIAL_STATE, action) => {
    let sid, startTime, endTime, correct, total;

    switch (action.type) {
        case TYPE_START_TEST:
            ({ sid, startTime, total } = action.payload);

            return saveLesson(state, sid, { ...DEFAULT_LESSON, sid, startTime, total});

        case TYPE_STOP_TEST:
            ({ sid, endTime } = action.payload);
            return saveLesson(state, sid, { endTime, isStoped: true });

        case TYPE_COMPLETE_TEST: 
            ({ sid, endTime } = action.payload);
            return saveLesson(state, sid, { endTime, isComplited: true });

        case TYPE_END_TEST:
            ({ sid, total, correct } = action.payload);
            return saveLesson(state, sid, { total: total || 0, correct: correct || 0 });
        default: return state;
    }
}

const saveLesson = (state, sid, opts) => {
    const { lessons } = state;
    const resultState = { ...state };

    let lesson = lessons[sid];
    
    if (lesson) {
        lesson = { ...lesson, ...(opts || {}) };

        resultState.lessons = {
            ...lessons,
            [sid]: lesson
        }
    } else {
        resultState.lessons = {
            ...lessons,
            [sid]: opts
        }
    }

    return resultState;
};