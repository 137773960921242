export const SET_OPTIONS_PROP = "SET_OPTIONS_PROP";
/*
export const setTestTimer = (num) => {};

export const setTestDelay = (num) => {};

export const setTestMode = (val) => {};

export const selectLang = (locale) => {};

export const toggleDelayTimer = (val) => {};

export const toggleExampleTimer = (val) => {};
*/

/**
 * 
 * @param {*} propName 
 * @param {*} value 
 */

export const setPropValue = (prop, value) => {
    return {
        type: SET_OPTIONS_PROP,
        payload: { prop, value }
    };
};
