import _ from 'lodash';
import i18next from 'i18next';

export const checkExample = (value) => {
    const res = [];

    const values = String(value).split(' ');

    if (values.length > 0) {
        _.forEach(values, (v) => {
            if (!v) return;

            const n = Number(v);

            if (n) {
                res.push(n);
            } else {
                throw new Error(i18next.t("errors.wrong_example_format"))
            }
        });
    }

    return res;
};