import _ from 'lodash';
import {
    TYPE_START_TEST,
    TYPE_STOP_TEST,
    TYPE_CHECK_TEST,
    TYPE_ADD_EXAMPLE,
    TYPE_SET_EXAMPLES,
    TYPE_REMOVE_EXAMPLE,
    TYPE_REMOVE_ALL_EXAMPLE,
    TYPE_SELECT_EXAMPLE,
    TYPE_NEXT_EXAMPLE,
    TYPE_SELECT_STEP,
    TYPE_PAUSE_TEST,
    TYPE_RESUME_TEST,
    TYPE_END_TEST
} from '../actions';

import {
    EXAMPLER_STATE,
    RESULTER_STATE
} from '../../const';

const INIT_STATE = {
    current: 0,
    currentLesson: null, 
    step: null,

    testStart: false,
    testComplete: false,
    testPause: true,
    timerValue: null,

    examples: [
        [3, -2, 1],
        [1, 1, 2],
        [2, 5, -1],
        [3, 5, 1],
        [2, 2, 5],
    ],

    // current lesson's sid
};

export default (state = INIT_STATE, action) => {
    const { current, examples, testStart, testComplete } = state;
    const len = examples.length;

    console.log("State: ",  current, examples, testStart, testComplete);
    if (!action || !_.isPlainObject(action) || !action.type || typeof action.type !== 'string') {
        return state;
    }

    switch (action.type) {
        case TYPE_SELECT_STEP: 
            if (action.payload && typeof action.payload === 'string') {
                return {
                    ...state,
                    step: action.payload
                }
            }
            break;

        case TYPE_START_TEST:
            return {
                ...state,
                current: 0,
                testStart: true,
                testComplete: false,
                testPause: false,
                currentLesson: action.payload.sid
            };

        case TYPE_PAUSE_TEST: 
            return {
                ...state,
                testPause: true
            };

        case TYPE_RESUME_TEST:
            return {
                ...state,
                testPause: false
            };

        case TYPE_ADD_EXAMPLE:
            console.log("TYPE_ADD_EXAMPLE", action);
            if (_.isArray(action.payload) && action.payload.length > 1 && action.payload.length < 6) {
                return {
                    ...state,
                    examples: [...examples, action.payload]
                }
            }

            return state;

        case TYPE_REMOVE_EXAMPLE:
            if (_.isNumber(action.payload)) {
                const t = [ ...examples ];
                t.splice(action.payload, 1);

                return {
                    ...state,
                    examples: t
                }
            }

            return state;

        case TYPE_REMOVE_ALL_EXAMPLE:
            return { ...state, examples: []};    

        case TYPE_SELECT_EXAMPLE:
            console.log("TYPE_SELECT_EXAMPLE", action, len);
            if (_.isNumber(action.payload) && action.payload >= 0 && action.payload < len) {
                

                return {
                    ...state,
                    current: _.toInteger(action.payload)
                }
            }

            return state;

        case TYPE_NEXT_EXAMPLE: 
            if (testStart && !testComplete) {
                if (current < len - 1) {
                    return {
                        ...state,
                        current: current + 1,
                    };
                }

                return {
                    ...state,
                    testStart: false,
                    testComplete: true
                };
            }
            
            return state;

        case TYPE_END_TEST: 
        case TYPE_STOP_TEST: 
            return {
                ...state,
                step: EXAMPLER_STATE,
                testStart: false,
                testComplete: false,
                testPause: false,
                current: 0,
                currentLesson: null
            };    

        case TYPE_CHECK_TEST: 
            console.log("TYPE_CHECK_TEST");
            
            return {
                ...state,
                step: RESULTER_STATE,
                current: 0
            };   

        case TYPE_SET_EXAMPLES:
            console.log("TYPE_SET_EXAMPLES: ", action)
            if (_.isArray(action.payload) && action.payload.length > 0) {
                return {
                    ...state,
                    examples: action.payload
                };
            }

            return state;
            
        default: return state;
    }

};