import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import { motion, useAnimation } from "framer-motion"

const LoadingOverlay = (props) => {
    const { show } = props;
    const [ hidden, setHidden ] = useState(false);
    const controls = useAnimation();

    useEffect(() => {
        if (show === false) {
            controls.start({
                opacity: 0,
                transition: { duration: 1 },
            });

            setTimeout(() => {
                setHidden(true);
            }, 1000);
        } else {
            setHidden(false);
        }
    }, [show]);

    if (hidden) return null;

    return (
        <motion.div
            animate={controls}
            className="ucmas-loading-overlay"
        >
            <Spin size="large" />
        </motion.div>
    );

}

export default LoadingOverlay;
