import { combineReducers } from 'redux';

import AppReducer from './AppReducer';
import SolverReducer from './SolverReducer';
import OptionsReducer from './OptionsReducer';
import LessonsReducer from './LessonsReducer';
import PresetReducer from './PresetReducer';


export default combineReducers({
    app: AppReducer,
    solver: SolverReducer,
    options: OptionsReducer,
    presets: PresetReducer,
    lessons: LessonsReducer
});
