import _ from 'lodash';
import React, { Component } from 'react';
import { Button, Result, Progress } from 'antd';
import { connect } from 'react-redux';
import i18next from 'i18next';
import UIfx from 'uifx';
import { motion } from "framer-motion";
import moment from 'moment';

import {
    PauseOutlined,
    CaretRightOutlined,
    BorderOutlined,
    ArrowRightOutlined,
    BarsOutlined
} from '@ant-design/icons';

import {
    startTest,
    pauseTest,
    resumeTest,
    stopTest,
    checkTest,
    completeTest,
    nextExample
} from '../redux/actions';

import bipAudio from '../assets/audio/bip_1.mp3';

const bip = new UIfx(bipAudio);

class Exampler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numbers: [],
            currentTime: null,
            isHidden: false,
            isPrepare: false
        };

        this.testInterval = null;

        this.doStart = this.doStart.bind(this);
        this.doPause = this.doPause.bind(this);
        this.doResume = this.doResume.bind(this);
        this.doNext = this.doNext.bind(this);
        this.doStop = this.doStop.bind(this);
        this.doCheck = this.doCheck.bind(this);
    }

    componentDidMount() {
        const { testStart, testComplete, testPause, sid } = this.props;

        console.log("Current lesson's sid: ", sid);
        if (testStart && !testComplete && !testPause) {
            this.go();
        }
    }

    componentDidUpdate(oldProps) {
        const { testComplete, sid } = this.props;

        if (testComplete && testComplete !== oldProps.testComplete) {
            clearInterval(this.testInterval);
            this.props.completeTest(sid);
        }
    }

    componentWillUnmount() {
        clearInterval(this.testInterval);
    }

    go(fromTime, ops = {}) {
        const { timer } = this.props;

        clearInterval(this.testInterval);

        this.setState({ currentTime: fromTime || timer || 10, isHidden: false, isPrepare: false, ...ops });
        this.testInterval = setInterval(() => this.tik(), 1000);
    }

    tik() {
        const { isManual } = this.props;
        const { currentTime, isPrepare } = this.state;
        const time = currentTime - 1;

        if (time <= 0) {
            clearInterval(this.testInterval);

            if (isManual) {
                this.hide();
                this.testInterval = setInterval(() => this.tak(), 1000);
            } else if (!isPrepare) {
                this.doPrepare()
            } else {
                this.doNext()
            }
        } else {
            this.setState({ currentTime: time });
        }

        
        this.doBip();
    }

    tak() {
        const { currentTime } = this.state;

        this.setState({ currentTime: currentTime + 1});
    }

    hide(val = true) {
        this.setState({ isHidden: !!val });
    }

    doStart() {
        const { examples } = this.props;
        
        this.go();
        this.props.startTest(_.size(examples));
    }

    doPause() {
        clearInterval(this.testInterval);

        this.props.pauseTest();
    }

    doResume() {
        const { currentTime } = this.state;

        this.go(currentTime);
        this.props.resumeTest();
    }

    doNext() {
        this.go();
        this.props.nextExample();
    }

    doPrepare() {
        const { delay } = this.props;

        this.go(delay, { isPrepare: true });
    }

    doStop() {
        const { sid } = this.props;

        clearInterval(this.testInterval);
        this.props.stopTest(sid);
    }

    doCheck() {
        clearInterval(this.testInterval);
        this.props.checkTest();
    }

    doBip() {
        const { currentTime, isPrepare } = this.state;
        const { playSound, bipDelay } = this.props;

        if (!playSound) return;

        if (isPrepare && bipDelay > 0) {
            if (currentTime === bipDelay) {
                bip.play();
            }
        }
    }

    _getExample(index) {
        const { examples, current } = this.props;

        if (_.isArray(examples) && examples.length > 0 && _.isNumber(current) && current >= 0 && current < examples.length) {
            return examples[current];
        }

        return [];
    }

    renderExample() {
        const { isHidden, isPrepare } = this.state;
        const { current, testStart, testComplete } = this.props;

        const ex = this._getExample(current);

        if (testComplete) {
            return (
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 100, duration: 0.3 }}
                >
                    <Result
                        status="success"
                        title={i18next.t("test_complete_sucessfully")}
                        subTitle={i18next.t("test_complete_sucessfully_subtitle")}
                        extra={[]}
                    />
                </motion.div>
            );
        } else if (isPrepare) {
            return (
                <motion.div
                    key={"prepare_block"}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 200, duration: 0.5 }}
                >
                    <div className="prepare-block">Prepare!</div>
                </motion.div>
            );
        } else if (!testStart) {
            return (
                <motion.div
                    key={"example_" + current}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 200, duration: 0.3 }}
                >
                    <Result
                        status="info"
                        title={i18next.t("begin_test_title")}
                        extra={[]}
                    />
                </motion.div>

            );
        }

        if (!ex || ex.length === 0) return null;

        return (
            <motion.div
                key={"example_" + current}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 200, duration: 0.5 }}
            >
                <div className={`example-container type-${ex.length} ${isHidden ? "hidden" : ""}`}>
                    <div className="header">
                        {i18next.t("example_header", { number: current + 1})}
                    </div>
                    <div className="numbers">
                        {ex.map((n, i) => <div className="number" key={`${i}_${current}_${n}`}>{n}</div>)}
                    </div>
                </div>

            </motion.div>
        );
    }

    renderExamleTimer() {
        const { timer, testStart, testPause, showExampleTimer } = this.props;
        const { currentTime, isPrepare, isHidden } = this.state;

        const percent = currentTime / timer * 100;

        if (isPrepare || !showExampleTimer || !testStart || isHidden) return null;

        let status = 'normal';

        if (currentTime < 3) {
            status = 'exception';
        }

        return (
            <div className="timer left">
                <motion.div
                    key={"exampler_timer"}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 100, duration: 0.5 }}
                >
                    <Progress
                        type="circle"
                        percent={percent}
                        format={() => testPause ? <PauseOutlined /> : `${currentTime}`}
                        width={50}
                        status={status}
                    />
                </motion.div>
            </div>
        );
    }

    renderDelayTimer() {
        const { delay, testPause, testStart, showDelayTimer } = this.props;
        const { currentTime, isPrepare } = this.state;

        const percent = currentTime / delay * 100;

        if (!isPrepare || !showDelayTimer || !testStart) return null;

        let status = 'exception';

        return (
            <div className="timer right">
                <motion.div
                    key={"delay_timer"}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 100, duration: 0.5 }}
                >
                    <Progress
                        type="circle"
                        percent={percent}
                        format={() => testPause ? <PauseOutlined /> : `${currentTime}`}
                        width={50}
                        status={status}
                    />
                </motion.div>
            </div>
        );
    }

    renderHiddenTimer() {
        const { isHidden, currentTime } = this.state;

        if (!isHidden) return null;
        
        return (
            <div className="hidden-timer">
                <motion.div
                    key={"hidden_timer"}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 100, duration: 0.5 }}
                >
                    {moment(currentTime * 1000).format("mm:ss")}
                </motion.div>
            </div>
        )
    }

    renderControls() {
        const { testPause, testStart, testComplete, isManual, examples } = this.props;

        const buttons = [];

        if (testStart) {
            if (testPause) {
            buttons.push(<Button className="button" size="large" type="primary" onClick={this.doResume} icon={<CaretRightOutlined />}>{i18next.t("buttons.resume")}</Button>);
                buttons.push(<Button className="button" size="large" type="warning" onClick={this.doStop} icon={<BorderOutlined />}>{i18next.t("buttons.stop")}</Button>);
            } else {
                if (isManual) {
                    buttons.push(<Button className="button" size="large" type="primary" onClick={this.doNext} icon={<ArrowRightOutlined />}>{i18next.t("buttons.next")}</Button>);
                } else {
                    buttons.push(<Button className="button" size="large" type="primary" onClick={this.doPause} icon={<PauseOutlined />}>{i18next.t("buttons.pause")}</Button>);
                }

                buttons.push(<Button className="button" size="large" type="warning" onClick={this.doStop} icon={<BorderOutlined />}>{i18next.t("buttons.stop")}</Button>);
            }
        } else if (testComplete) {
            buttons.push(<Button className="button" size="large" type="primary" onClick={this.doCheck} icon={<BarsOutlined />}>{i18next.t("buttons.check_result")}</Button>);
        } else {
            buttons.push(<Button className="button" size="large" type="primary" onClick={this.doStart} icon={<CaretRightOutlined />}>{i18next.t("buttons.start_test")}</Button>);
        }

        return (
            <div className="contrlos">
                {this.renderExamleTimer()}
                {buttons}
                {this.renderDelayTimer()}
                {this.renderHiddenTimer()}
            </div>
        );
    }

    render() {
        return (
            <>
                {this.renderExample()}
                {this.renderControls()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { isManual, timer, delay, showDelayTimer, showExampleTimer, playSound, bipDelay, lang } = state.options;
    const { step, current, currentLesson:sid, examples, testPause, testStart, testComplete } = state.solver;
    const { initialized } = state.app;

    return {
        sid,
        initialized,
        lang,
        showDelayTimer,
        showExampleTimer,
        timer,
        delay,
        step,
        current,
        examples,
        testPause,
        testStart,
        testComplete,
        isManual,
        playSound,
        bipDelay
    }
};

const mapDispatchToProps = {
    startTest,
    pauseTest,
    resumeTest,
    stopTest,
    completeTest,
    checkTest,
    nextExample
};

export default connect(mapStateToProps, mapDispatchToProps)(Exampler);